





































































































import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo } from "@/components/Notification";
import { AxiosError } from "axios";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  name: "btn-resend-documents",
  props: {
    patient: Object,
    step: String,
  },
  data() {
    return {
      sendvia: "email",
      dialog: false,
      loading: false,
      reason: "",
      body: {
        reasons: null,
        procedureId: "",
        step: "",
        deviceUuid: "",
      },
    };
  },
  computed: {
    ...mapGetters("crmConfigurationsModule", ["getDevicesAvailable"]),
  },
  async mounted() {
    await this.actGetDevices();
  },
  methods: {
    ...mapActions("crmConfigurationsModule", ["actGetDevices"]),
    resend() {
      this.loading = true;
      this.body.procedureId = this.patient.id;
      this.body.step = this.step;
      (this as any).body.reasons = [this.reason];
      if ((this as any).sendvia == "email") {
        delete (this as any).body.deviceUuid;
      }

      getAPI
        .post("/patient/resendStep", this.body)
        .then(() => {
          this.cancel();

          notifyInfo("The documents have been sended");
          this.$emit("documents-sended");
        })
        .catch((error: AxiosError) => {
          const message =
            (error as any).response.data.message ||
            (error as any).response.data.details;

          if (error.response?.status == 404 || error.response?.status == 400) {
            this.loading = false;
            notifyError(message);
          }
          this.cancel();
        });
    },
    cancel() {
      this.loading = false;
      this.dialog = false;
      this.reason = "";
      this.sendvia = "email";
    },
  },
});
